import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import { SessionState, reducer as session } from "./session";

export interface IState {
  session: SessionState;
}

const reducers = combineReducers({
  session,
});

const rootReducer = (
  state: CombinedState<IState> | undefined,
  action: AnyAction,
): CombinedState<IState> => {
  if (action.type === "session/cleanup") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
