/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import React, { Component, ErrorInfo, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { IState } from "store/reducers";
// import { SessionState } from 'store/features/session/slice';
// import { logUserEvent, telemetryAction } from 'utils/useTelemetry';

const ErrorFallbackWrapper = styled("div")(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  minWidth: "0",
  wordWrap: "break-word",
  backgroundColor: "#fff",
  backgroundClip: "border-box",
  border: "1px solid rgba(0, 0, 0, 0.125)",
  borderRadius: "0.25rem",
  margin: "3rem 0",
}));

const ErrorFallbakcHeader = styled("div")(() => ({
  padding: "0.75rem 1.25rem",
  marginBottom: "0",
  backgroundColor: "rgba(0, 0, 0, 0.03)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.125)",

  "& > p > span": {
    cursor: "pointer",
    color: "#0077ff",
    marginLleft: "5px",
  },
}));

const ErrorFallbackBody = styled("div")(() => ({
  flex: "1 1 auto",
  minHeight: "1px",
  padding: "1.25rem",
}));

interface Props {
  children?: ReactNode;
  // userSession: SessionState;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
    };
  }
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // const { user, currentOrganizationId, currentProjectId, sessionId } = this.props.userSession;

    // cut error for less string
    const firstComponentStackError = errorInfo.componentStack.split("\n")[1];
    // logUserEvent({
    //     user_id: user?.id || -1,
    //     organization_id: currentOrganizationId || -1,
    //     action: telemetryAction.runtime_error,
    //     payload_json: {
    //         project_id: currentProjectId || -1,
    //         sessionId: sessionId || '',
    //         error,
    //         errorInfo: firstComponentStackError
    //     }
    // });
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorFallbackWrapper>
          <ErrorFallbakcHeader>
            <p>
              There was an error.
              <span
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload this page
              </span>
            </p>
          </ErrorFallbakcHeader>
          <ErrorFallbackBody>
            <details>
              <summary>Click for error details</summary>
              {this.state.error.message}
            </details>
          </ErrorFallbackBody>
        </ErrorFallbackWrapper>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: IState) => {
  return {
    userSession: state.session,
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
