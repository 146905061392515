import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AccessTokenResponse } from "models/api/response.types";
import { selectUser, setUser } from "store/reducers/session";
import MainLoader from "components/LoadingOverlays/MainLoader";
import routePaths from "routes/routePaths";
import { isProd } from "utils/general";
import { useQuery } from "@tanstack/react-query";
import authService from "api/authService";
import { AxiosError } from "axios";
import handleAxiosError from "utils/handleAxiosAlert";

// lazy rendering
const Sites = React.lazy(() => import("pages/Sites"));
const Site = React.lazy(() => import("pages/Site"));

const AuthRoute: React.FC<{ user: AccessTokenResponse | undefined }> = ({ user }) => {
  const dispatch = useDispatch();
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      setAuthorized(false);
      window.open(
        `https://accounts.${isProd() ? "petal" : "petal-dev"}.org/login?service=connect`,
        "_self",
      );
    }
  }, [user]);

  // refethching user on window focus
  useQuery({
    queryKey: ["user", user?.id],
    queryFn: () => authService.fetchUserInfo(user?.id || 0),
    refetchInterval: 3000,
    enabled: !!user,
    onError: (err: AxiosError) => handleAxiosError(err, dispatch),
    onSuccess: () => {
      if (!authorized) {
        setAuthorized(true);
      }
    },
  });

  if (!authorized) {
    return <MainLoader />;
  }

  return <Outlet />;
};

const Router: React.FC = () => {
  const user: AccessTokenResponse | undefined = useSelector(selectUser);

  return (
    <Suspense fallback={<MainLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to={routePaths.sites} replace />} />
          <Route path={routePaths.default} element={<AuthRoute user={user} />}>
            <Route path="" element={<Navigate to="sites" replace />} />
            <Route path={routePaths.sites} element={<Sites />} />
            <Route path={routePaths.selectedSite} element={<Site />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
