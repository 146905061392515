import axios, { AxiosPromise } from "axios";
import {
  AuthBody,
  SignupCodeAcceptBody,
  SignupEmailSendBody,
  UserUpdateBody,
} from "models/api/request.types";
import {
  AccessTokenResponse,
  SignupUserPermittedResponse,
  SuccessResponse,
  UserDetailed,
} from "models/api/response.types";

const login = (requestBody: AuthBody): AxiosPromise<AccessTokenResponse> => {
  return axios.post<AccessTokenResponse>(`/api/auth`, requestBody);
};

const checkEmailPermission = (email: string): AxiosPromise<SignupUserPermittedResponse> => {
  return axios.get<SignupUserPermittedResponse>(`/api/signup/user/permitted?email=${email}`);
};

const sendVerificationCode = (requestBody: SignupEmailSendBody): AxiosPromise<any> => {
  return axios.post<any>(`/api/signup/email/send`, requestBody);
};

const authUsingVerificationCode = (
  requestBody: SignupCodeAcceptBody,
): AxiosPromise<AccessTokenResponse> => {
  return axios.post<AccessTokenResponse>(`/api/signup/code/accept`, requestBody);
};

const createNewUser = (requestBody: SignupEmailSendBody): AxiosPromise<any> => {
  return axios.post<any>("/api/signup/create", requestBody);
};

const updateUser = (
  userId: number,
  requestBody: Partial<UserUpdateBody>,
): AxiosPromise<UserDetailed> => {
  return axios.post<UserDetailed>(`/api/user/${userId}/update`, requestBody);
};

const sendForgotPasswordVerificationCode = (
  requestBody: SignupEmailSendBody,
): AxiosPromise<SuccessResponse> => {
  return axios.post("/api/signup/forgot_password/email/send", requestBody);
};

const createPersonalOrganization = (userId: number): AxiosPromise<UserDetailed> => {
  return axios.post<UserDetailed>(`/api/user/${userId}/organization/create`);
};

const fetchUserInfo = (userId: number): AxiosPromise<UserDetailed> => {
  return axios.get<UserDetailed>(`/api/user/${userId}`);
};

const logout = (): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/logout`);
};

export const authService = {
  login,
  checkEmailPermission,
  sendVerificationCode,
  authUsingVerificationCode,
  createNewUser,
  updateUser,
  sendForgotPasswordVerificationCode,
  createPersonalOrganization,
  fetchUserInfo,
  logout,
};

export default authService;
