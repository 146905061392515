import { createTheme } from "@mui/material";

// module to add custom colors to theme
declare module "@mui/material/styles" {
  interface Theme {
    grey: {
      main: string;
    };
    purple: {
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    grey?: {
      main?: string;
    };
    purple?: {
      main?: string;
    };
  }
}

const theme = createTheme({
  grey: {
    main: "#ECECEC",
  },
  purple: {
    main: "#9E77ED",
  },
  palette: {
    mode: "light",
    primary: { main: "#FAFAFA", light: "#FFF", dark: "#F7F7F7" },
    secondary: { main: "#27569B", light: "#EAF1FB", dark: "#DFE7F2" },
    warning: { main: "#ed6c02" },
  },
  spacing: 8,
  typography: {
    body2: {
      fontSize: "13px",
    },
    body1: {
      fontSize: "12px",
    },
    h4: {
      fontSize: "30px",
    },
    h6: {
      fontSize: "16px",
    },
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 600,
      md: 900,
      lg: 1150,
      xl: 1480,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "16px",
          textTransform: "none",
          fontWeight: "normal",
          "&.MuiButton-text": {
            background: "transparent",
            border: "1px solid transparent",
            "&.MuiButton-textSecondary": {
              color: "#27569B",
              "&:not(.Mui-textUnderline)": {
                "&.Mui-focusVisible, :hover": {
                  background: "#F7F7F7",
                },
                "&.Mui-disabled": {
                  background: "#ECECEC",
                  color: "rgba(0, 0, 0, 0.46)",
                },
              },
              "&.Mui-textUnderline": {
                "&.Mui-focusVisible, :hover": {
                  textDecoration: "underline",
                },
              },
            },
            "&.MuiButton-textPrimary": {
              "&.Mui-textUnderline": {
                "&.Mui-focusVisible, :hover": {
                  textDecoration: "underline",
                },
              },
            },
            color: "#6C6C6C",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.46)",
            },
          },
          "&.MuiButton-outlined.MuiButton-outlinedSecondary": {
            border: "1px solid #27569B",
            "&.Mui-focusVisible": {
              backgroundColor: "rgba(39, 86, 155, 0.04)",
            },
          },
          "&.MuiButton-contained": {
            boxShadow: "none",
            "&.Mui-focusVisible, :hover": {
              boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            },
            "&.MuiButton-containedSecondary": {
              background: "#EAF1FB",
              color: "#27569B",
              "&.Mui-disabled": {
                background: "#e2e2e2",
                color: "#8d8d8d",
                "&.MuiLoadingButton-loading": {
                  color: "transparent",
                },
              },
            },
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        disableRipple: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            borderRadius: "16px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E9E9E9",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiSvgIcon-colorLight": {
            fill: "#8b8b8b",
          },
          "&.MuiSvgIcon-colorPrimary": {
            fill: "#6C6C6C",
          },
          "&.MuiSvgIcon-fontSizeSmall": {
            width: "14px",
            height: "14px",
          },
          "&.MuiSvgIcon-fontSizeMedium": {
            width: "18px",
            height: "18px",
          },
          "&.MuiSvgIcon-fontSizeLarge": {
            width: "25px",
            height: "25px",
          },
          "&.sign-in-helper, &.org-creation-helper": {
            width: "12px",
            height: "12px",
            fill: "red",
            margin: "auto 4px auto 0",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "& .MuiListItemText-root.MuiListItemText-inset": {
            paddingLeft: "30px",
          },
          "& .MuiListItemIcon-root.menu-icon": {
            minWidth: "30px",
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        disableShrink: true,
      },
      styleOverrides: {
        root: {
          color: "#27569B",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          "&.MuiInputBase-root": {
            borderRadius: "16px",
            "&.Mui-disabled": {
              background: "#F7F7F7",
            },
            "& input.Mui-disabled": {
              WebkitTextFillColor: "#8B8B8B",
            },
          },
          "&.MuiInputBase-colorSecondary": {
            "& .MuiOutlinedInput-notchedOutline ": {
              borderColor: "#AAAAAA",
            },
          },
          "& .Mui-error": {
            color: "#d32f2f",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked > svg": {
            fill: "#007DFF",
          },
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: "#ECECEC",
            "&:hover": {
              background: "#EAF1FB",
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
          "& .Mui-expanded": {
            boxShadow: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
          "&:before": {
            opacity: 0,
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            border: "1px solid #ECECEC",
            borderRadius: "50px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          minHeight: 40,
          alignItems: "center",
          lineHeight: "35px",
          "& .Mui-expanded": {},
          "&.Mui-expanded": {
            minHeight: 40,
            // borderRadius: 0
          },
          "&:hover": {
            background: "#ECECEC",
          },
          background: "#FFF !important",
          color: "#303030",
        },
        content: {
          minHeight: 40,
          alignItems: "center",
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
            borderBottom: "1px solid #ECECEC",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          height: "100%",
          zIndex: 50,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "100%",
          minHeight: "0 !important",
          padding: "0 !important",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-track": {
            backgroundColor: "#000 !important",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "2.6875rem",
          height: "100%",
          "& button": {
            minHeight: 0,
            height: "100%",
            padding: "0",
          },
          "& .": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgb(51, 68, 85)",
            transition: "none",
          },
          "& .MuiTabs-flexContainer": {
            height: "100%",
          },
          "& .MuiTabs-indicator": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgb(51, 68, 85)",
            transition: "none",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiMenuItem-root.Mui-selected": {
            background: "#ECECEC",
          },
          "& .MuiMenuItem-root.Mui-selected:hover": {
            background: "#EAF1FB",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#27569B",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 5px",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fill: "#007DFF",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& span": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: "0 !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.Typography-light": {
            color: "#8b8b8b",
          },
          "&.Typography-dark": {
            color: "#4c4c4c",
          },
        },
      },
    },
  },
});

export default theme;
