/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessTokenResponse } from "models/api/response.types";
import { IState } from ".";

export interface SessionState {
  user: AccessTokenResponse | undefined;
  authEmailHandoff: string;
}

export const initialState: SessionState = {
  user: undefined,
  authEmailHandoff: "",
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AccessTokenResponse>) {
      state.user = action.payload;
    },
    setAuthEmailHandoff(state, action: PayloadAction<string>) {
      state.authEmailHandoff = action.payload;
    },
  },
});

export const { reducer } = sessionSlice;
export const { setUser, setAuthEmailHandoff } = sessionSlice.actions;

export const selectUser = createSelector(
  (state: IState) => state.session.user,
  (item) => item,
);

export const selectAuthEmailHandoff = createSelector(
  (state: IState) => state.session.authEmailHandoff,
  (item) => item,
);
