import React from "react";
import { Box, styled } from "@mui/material";

const LoaderContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  zIndex: "999",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.primary.dark,
  "& .logo": {
    animationName: "rotation",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
}));

const MainLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <img className="logo" width="110" alt="Petal" src="/img/petal-logo-small.png" />
    </LoaderContainer>
  );
};

export default MainLoader;
