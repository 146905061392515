/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Router from "routes/Router";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "App-styles";
import { selectUser, setUser } from "store/reducers/session";
import { useDispatch, useSelector } from "react-redux";
import "mathJax.css";
import axios from "axios";
import ErrorBoundary from "ErrorBoundary";
import { CssBaseline } from "@mui/material";
import authService from "api/authService";
import MainLoader from "components/LoadingOverlays/MainLoader";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  // need to check for user identity
  const [checkPass, setCheckPass] = useState<boolean>(false);

  // sso-login check
  useEffect(() => {
    // check data only if user doesn't exists
    // if user exists apis will cover checking scenario
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    if (!user && token) {
      const userData = JSON.parse(token);
      axios.defaults.headers.common.Authorization = `Bearer ${userData.access_token}`;
      authService
        .fetchUserInfo(userData.id)
        .then(({ data }) => {
          dispatch(
            setUser({
              access_token: userData.access_token,
              ...data,
            }),
          );
          setCheckPass(true);
        })
        .catch(() => {
          setCheckPass(true);
        });
    } else {
      setCheckPass(true);
    }
  }, [user]);

  axios.defaults.headers.common.Authorization = `Bearer ${user?.access_token}`;

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {checkPass && <Router />}
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

export default App;
